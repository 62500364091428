import React, { useState, useRef } from "react";
import {
  TextField,
  Box,
  Button,
  Grid,
  Container,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { fetchTokenDetails, approveToken } from '../api/BaseCall'
import SnackbarShow from '../components/SnackbarShow'

const OTPEnterPage = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({ open: false, message: '', severity: '' });
  const inputRefs = useRef([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Email validation function
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailError(!validateEmail(value));
  };

  const handleOtpChange = (index, event) => {
    const value = event.target.value.replace(/\D/g, ""); 
    if (!/^\d?$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text").replace(/\D/g, "");
    if (/^\d{6}$/.test(pastedText)) {
      setOtp(pastedText.split(""));
      pastedText.split("").forEach((char, index) => {
        inputRefs.current[index].value = char;
      });
      inputRefs.current[5].focus(); // Move focus to the last box
    }
  };

  const isFormValid = validateEmail(email) && otp.every((char) => char);

  const handleSubmit = async () => {
    // console.log(`Email: ${email}, OTP: ${otp.join("")}`)
    const token = otp.join("")
    setLoading(true)
    try {
      const tokenDetails = await fetchTokenDetails(token)
      if (!tokenDetails || tokenDetails.length === 0) {
        setSnackbarMessage({ open: true, message: "There is no two-fa session to approve.", severity: 'error' });
        return
      }
      if (tokenDetails.email.toLowerCase() !== email.toLowerCase()){
        setSnackbarMessage({open: true, message: "Entered email id does't match with the OTP details.", severity:'error'})
        return
      }
      if (tokenDetails.active) {
        setSnackbarMessage({ open: true, message: "Two Fa session alredy approved.", severity: 'success' });
      } else {
        const result = await approveToken(tokenDetails)
        console.log(result)
        setSnackbarMessage({open:true, message: result.message, severity: 'success'})
      }
    } catch (error) {
      setSnackbarMessage({ open: true, message: error.message , severity:'error' });
    } finally {
      setOtp(["", "", "", "", "", ""])
      setEmail("")
      setLoading(false)
    }
  };
  const snackbarMessageComp = (snackbarMessage.open) ? (<SnackbarShow data={snackbarMessage} setSnackbarMessage={setSnackbarMessage} />) : null;

  return (
    <Container maxWidth="sm">
      {snackbarMessageComp}
      <Box
        sx={{
          mt: 4,
          p: 3,
          boxShadow: 3,
          borderRadius: 2,
          textAlign: "center",
          width: isMobile ? "90%" : "auto",
          mx: "auto",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Enter Two-Factor OTP
        </Typography>
        <TextField
          fullWidth
          label="Email"
          type="email"
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? "Please enter a valid email address" : ""}
          sx={{ mb: 3 }}
        />
        <Grid container spacing={1} justifyContent="center">
          {otp.map((value, index) => (
            <Grid item key={index}>
              <TextField
                inputRef={(el) => (inputRefs.current[index] = el)}
                value={value}
                onChange={(e) => handleOtpChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={handlePaste}
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: "center",
                    fontSize: "20px",
                    width: "40px",
                    height: "40px",
                    padding: "10px",
                  },
                  inputMode: "numeric", // Mobile keyboard opens number pad
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isFormValid || loading}
          sx={{ mt: 3, width: isMobile ? "100%" : "auto", height: "45px" }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
        </Button>
      </Box>
    </Container>
  );
};

export default OTPEnterPage;
