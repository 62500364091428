import * as React from 'react';
import { Snackbar, Alert } from '@mui/material';


const alertStyle = {
    width: '400px', // Adjust width
    fontSize: '1rem', // Adjust font size
    fontWeight: 'bold', // Make text bold
    borderRadius: '8px', // Rounded corners
    boxShadow: 3, // Add slight shadow
    padding: '10px 16px', // Adjust padding
    display: 'flex',
    alignItems: 'center',
};

const SnackbarShow = ({ data, setSnackbarMessage }) => {
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarMessage({
            open: false,
            message: '',
            severity: '',
        });
    };

    return (
        <Snackbar
            open={data.open}
            autoHideDuration={3586}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                onClick={handleClose}
                severity={data.severity}
                variant='filled'
                sx={alertStyle}
            >
                {data.message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarShow;