import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import TokenPage from './pages/TokenPage';
// import NotFoundPage from './pages/NotFound';
import OTPEnterPage from './pages/OTPEnterPage'
import Verify from './pages/Verify';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/:token"  Component={TokenPage} /> */}
        <Route path="/verify" element={<Verify />} />
        {/* <Route path="/*"  element={<NotFoundPage/>} /> */}
        <Route path="/" Component={OTPEnterPage} />
      </Routes>
    </Router>
  );
}

export default App;
